import _ from 'lodash';
import axios from 'axios';
import { DateTime } from 'luxon';
import Honeybadger from '@honeybadger-io/js';

import Config from '../../config';
import { Cookie } from '..';
import { Types } from '../../constants';
import {
  ICreateServiceTypePayload,
  IGlobalCosmicConfig,
  LoginFlowEnum,
  RequestStatusesEnum,
} from '../../constants/Types';
import { getFailedApiCallResponse } from '../utils';
import {
  IDefaultMenuItem,
  IMenuItemsResponse,
  IQuicksiteItem,
  IReferenceMenuItem,
  RoleMenuItem,
} from '../../Layouts/Types';
import {
  Oauth2AuthPayload,
  Oauth2RefreshTokenExchangePayload,
} from '../../components/Settings/Modals/RefreshTokenModal';

const { GATEWAY_ID } = Config;

export const getRefreshTokenRedirectUrlAPI = async (
  payload: Oauth2AuthPayload,
): Promise<{
  success: boolean;
  redirectUri?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post('/gateway/salesforce/oauth2/auth', payload, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200 && res.data?.redirectUri) {
      return { success: true, redirectUri: res.data.redirectUri };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getRefreshTokenAPI = async (
  payload: Oauth2RefreshTokenExchangePayload,
): Promise<{
  success: boolean;
  refreshToken?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post('/gateway/salesforce/oauth2/refresh-token-exchange', payload, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200 && res.data?.refreshToken) {
      return { success: true, refreshToken: res.data.refreshToken };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getUserRoles = async (): Promise<{
  success: boolean;
  roles?: Types.RoleType[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/roles', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      res.data.roles.forEach((currentRole: any) => {
        currentRole.main_layout_sorting = currentRole.main_layout_sorting
          ? currentRole.main_layout_sorting
          : [
              {
                title: 'shortCuts',
                weight: 21,
              },
              {
                title: 'featuredPosts',
                weight: 11,
              },
              {
                title: 'dashBoardHighlights',
                weight: 1,
              },
            ];
        currentRole.sidebar_layout_sorting = currentRole.sidebar_layout_sorting
          ? currentRole.sidebar_layout_sorting
          : [
              {
                title: 'marketWidget',
                weight: 1,
              },
              {
                title: 'marketUpdates',
                weight: 21,
              },
            ];
      });
      return { success: true, roles: res.data.roles };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const addUserRole = async (role: Partial<Types.RoleType>) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post('/gateway/roles', { ...role }, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getAllQuicksights = async (): Promise<{
  success: boolean;
  allQuicksights?: IQuicksiteItem[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/quicksights/all', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200 && res.data.length) {
      return { success: true, allQuicksights: res.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getDefaultMenuItems = async (): Promise<{
  success: boolean;
  defaultMenuItems?: RoleMenuItem[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/roles/navigation_bar/default_items', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200 && res.data?.items?.length) {
      return { success: true, defaultMenuItems: res.data.items };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateUserRole = async (
  id: string,
  role: Partial<Types.RoleType>,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  delete role.id;
  delete role.slug;

  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.put(`/gateway/roles/${id}`, role, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getCurrentApps = async (): Promise<{
  success: boolean;
  apps?: Types.GatewayApps[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get('/gateway/apps', { headers: { 'X-ACCESS-TOKEN': accessToken } });
      if (res.status === 200) {
        return { success: true, apps: res.data.apps };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getMenuItems = async (): Promise<{
  success: boolean;
  menuItems?: (IDefaultMenuItem | IReferenceMenuItem)[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res: IMenuItemsResponse = await axios.get('/gateway/roles/navigation_bar', {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });

      if (res.status === 200) {
        return { success: true, menuItems: res.data.items };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getCurrentRole = async (
  useCache = true,
): Promise<{
  success: boolean;
  role?: Types.RoleType;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get(`/gateway/roles/own${useCache ? '?cache=true' : ''}`, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });

      if (res.status === 200 && res.data?.role) {
        res.data.role.main_layout_sorting = res.data.role?.main_layout_sorting
          ? res.data.role.main_layout_sorting
          : [
              {
                title: 'shortCuts',
                weight: 1,
              },
              {
                title: 'featuredPosts',
                weight: 2,
              },
              {
                title: 'dashBoardHighlights',
                weight: 3,
              },
            ];

        res.data.role.sidebar_layout_sorting = res.data.role?.sidebar_layout_sorting
          ? res.data.role.sidebar_layout_sorting
          : [
              {
                title: 'marketWidget',
                weight: 1,
              },
              {
                title: 'marketUpdates',
                weight: 21,
              },
            ];

        res.data.role.colors = res.data.role?.colors
          ? res.data.role.colors
          : [
              { color: '#323d47', type: 'primary' },
              { color: '#00853f', type: 'secondary' },
            ];

        const colArr = res.data.role.colors;
        const primaryColor = colArr[0].color.trim();
        const secondaryColor = colArr[1].color.trim();
        if (!primaryColor) {
          res.data.role.colors[0].color = '#323d47';
        }
        if (!secondaryColor) {
          res.data.role.colors[1].color = '#00853f';
        }
        return { success: true, role: res.data.role };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const uploadLogoMedia = async (
  file: string | Blob,
  queryParams?: string,
): Promise<{
  success: boolean;
  media?: { url: string; imgix_url: string };
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const formData = new FormData();
    formData.append('file', file);

    const url = queryParams ? '/gateway/utils/media' + queryParams : '/gateway/utils/media';

    const res = await axios.post(url, formData, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, media: { url: res.data.url, imgix_url: res.data.imgix_url } };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getSharefileToken = async (code: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post('/gateway/sharefile/code', { code }, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.data.success) {
      return { success: true, ...res.data.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getSessionStatus = async (): Promise<{
  success: boolean;
  data?: {
    session: {
      role: { label: string; id: string; is_admin: boolean };
      roleShadowMode: { label: string; id: string };
      sessionShadowMode: { isActive: boolean; shadowUserEmail: string };
    };
  };
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/session', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const sharefileUrlBuilder = () => {
  const querryObject = {
    includeDeleted: false,
    expand: 'Children',
    select: [
      'Id',
      'Name',
      'FileCount',
      'CreatorNameShort',
      'Info',
      'Info/IsAHomeFolder',
      'Info/CanUpload',
      'Children/Id',
      'Children/Name',
      'Children/CreationDate',
      'Children/FileSizeBytes',
      'Children/CreatorNameShort',
      'Children/FileCount',
    ],
  };

  const queryEntries = Object.entries(querryObject);

  let queryString = '';

  for (let i = 0; i < queryEntries.length; i++) {
    const firstSymbol = i === 0 ? '' : '&';
    let subQuerry = '';
    if (!Array.isArray(queryEntries[i][1])) {
      subQuerry = `${queryEntries[i][0]}=${queryEntries[i][1]}`;
    }
    if (Array.isArray(queryEntries[i][1])) {
      subQuerry = [...(queryEntries[i][1] as string[])].map((el) => `${queryEntries[i][0]}=${el}`).join('&');
    }

    queryString = queryString + `${firstSymbol}${subQuerry}`;
  }

  return queryString;
};

export const getFilesAPI = async (
  domain: string,
  token: string,
  currentFolder: string,
  refreshToken: () => void,
): Promise<{
  success: boolean;
  status?: number;
  data?: any;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const querryString = sharefileUrlBuilder();

    const res = await axios.get(`/gateway/sharefile/${currentFolder}/item?${querryString}`, {
      headers: { 'X-ACCESS-TOKEN': accessToken, 'SHAREFILE-ACCESS-TOKEN': token, 'SHAREFILE-DOMAIN': domain },
    });
    if (res.status === 200) {
      return { success: true, data: res.data.item, status: res.status };
    }
    if (res.status === 401) {
      refreshToken();
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const downloadAPI = async (
  domain: string,
  token: string,
  id: string,
  downloadFileIds: string[],
): Promise<{
  success: boolean;
  DownloadUrl?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      `/gateway/sharefile/${id}/bulkDownload`,
      { downloadFileIds },
      {
        headers: { 'X-ACCESS-TOKEN': accessToken, 'SHAREFILE-ACCESS-TOKEN': token, 'SHAREFILE-DOMAIN': domain },
      },
    );

    if (res.status === 200 && res.data?.data?.DownloadUrl) {
      return { success: true, DownloadUrl: res.data.data.DownloadUrl };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getParentFolders = async (domain: string, token: string, id: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/sharefile/${id}/breadcrumbs`, {
      headers: { 'X-ACCESS-TOKEN': accessToken, 'SHAREFILE-ACCESS-TOKEN': token, 'SHAREFILE-DOMAIN': domain },
    });
    if (res.status === 200) {
      return { success: true, data: res.data.breadcrumbs?.value };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getWebViewUrl = async (
  domain: string,
  token: string,
  id: string,
): Promise<{
  success: boolean;
  data?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/sharefile/${id}/fileWebView`, {
      headers: { 'X-ACCESS-TOKEN': accessToken, 'SHAREFILE-ACCESS-TOKEN': token, 'SHAREFILE-DOMAIN': domain },
    });
    if (res.status === 200) {
      return { success: true, data: res.data.fileUrl };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const refreshSharefileToken = async (domain: string, token: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      '/gateway/sharefile/refresh',
      { token },
      {
        headers: {
          'X-ACCESS-TOKEN': accessToken,
          'SHAREFILE-DOMAIN': domain,
        },
      },
    );
    if (res.data.success) {
      return { success: true, ...res.data.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getDocLibraryFilesByQuery = async (
  domain: string,
  query: Types.IShareFileSearchQuery,
  shareFileAccessToken: string,
): Promise<{
  success: boolean;
  data?: Types.IDocLibraryFile[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.post(
        '/gateway/sharefile/search',
        { Query: { ...query } },
        {
          headers: {
            'X-ACCESS-TOKEN': accessToken,
            'SHAREFILE-ACCESS-TOKEN': shareFileAccessToken,
            'SHAREFILE-DOMAIN': domain,
          },
        },
      );
      if (res.status === 200) {
        return { success: true, data: res.data.data };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const uploadShareFile = async (
  domain: string,
  folder_id: string,
  access_token: string,
  file: File,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const data = new FormData();
    data.append('folder_id', folder_id);
    data.append('file', file);

    if (accessToken) {
      const res = await axios.post('/gateway/sharefile/upload', data, {
        headers: { 'X-ACCESS-TOKEN': accessToken, 'SHAREFILE-ACCESS-TOKEN': access_token, 'SHAREFILE-DOMAIN': domain },
      });
      if (res.status === 200) {
        return { success: true };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getPostsByQuery = async (size: number, page: number) => {
  try {
    const accessToken = Cookie.getAccessToken();
    let queryParams = `size=${size || 20}`;
    if (page > 0) {
      queryParams = `page=${page}&` + queryParams;
    }
    const res = await axios.get(`/gateway/queryPosts?${queryParams}`, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200 && res.data.success) {
      return {
        success: true,
        posts: res.data.posts.map((o: any) => ({ ...o, date: DateTime.fromISO(o.date), dateString: o.date })),
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getKeysFinancialPlanningAreasValues = async (id: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get(`/gateway/keysPlanningAreasValue/${id}`, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, keysPlanningAreasValue: res.data?.keysPlanningAreasValues };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getRSSFeed = async (): Promise<{
  success: boolean;
  feeds?: Types.RSSFeed[];
  newsTweets?: Types.Tweet[];
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/rss', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return {
        success: true,
        feeds: res.data.feeds || [],
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getRequestCases = async (id: string): Promise<any> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/cases?requestId=${id}`, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    return getFailedApiCallResponse(err);
  }
  return { success: false };
};

export const saveNewRequestType = async (data: ICreateServiceTypePayload) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const config = { headers: { 'X-ACCESS-TOKEN': accessToken } };
    const response = await axios.post(`/gateway/requests`, data, config);
    return response.data;
  } catch (error) {
    return { success: false };
  }
};

export const getCategories = async (): Promise<{
  success: boolean;
  categories?: string[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/posts/categories', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      const newCategories = res.data.categories || [];
      const filteredCategories = newCategories.filter((el: string) => el);
      return {
        success: true,
        categories: filteredCategories,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getRequestTemplates = async (): Promise<{
  success: boolean;
  requestTemplates?: Types.RequestTemplate[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const res = await axios.get('/gateway/requests/templates', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return {
        success: true,
        requestTemplates: res.data.data || res.data.data || [],
      };
    }
  } catch (err) {
    return getFailedApiCallResponse(err);
  }
  return {
    success: false,
  };
};

export const addRequestTemplate = async (data: Types.IRequestWithOptionalIds): Promise<Types.IRequestResponse> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      '/gateway/requests/templates',
      { ...data },
      { headers: { 'X-ACCESS-TOKEN': accessToken } },
    );
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateRequestTemplate = async (id: string, data: Types.IRequestWithOptionalIds) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.put(
      `/gateway/requests/templates/${id}`,
      { ...data },
      { headers: { 'X-ACCESS-TOKEN': accessToken } },
    );
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteRequestTemplate = async (
  id: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.delete(`/gateway/requests/templates/${id}`, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 204) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getRequestConfigs = async (): Promise<Types.IRequestConfigResponse> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const { data } = await axios.get('/gateway/requests/configs', { headers: { 'X-ACCESS-TOKEN': accessToken } });

    return {
      success: true,
      requestsConfig: data.data,
    };
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
};

export const addRequestConfig = async (data: Omit<Types.ICRMConfigData, 'id'>): Promise<Types.IRequestResponse> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      '/gateway/requests/configs',
      { ...data },
      { headers: { 'X-ACCESS-TOKEN': accessToken } },
    );
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteRequestConfig = async (
  id: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.delete(`/gateway/requests/configs/${id}`, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 204) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateRequestConfig = async (id: string, data: Omit<Types.ICRMConfigData, 'id'>) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.put(
      `/gateway/requests/configs/${id}`,
      { ...data },
      { headers: { 'X-ACCESS-TOKEN': accessToken } },
    );
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getRequestSetups = async (): Promise<{
  success: boolean;
  requestsSetups?: Types.IRequestTypeData[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/requests/setups`, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return {
        success: true,
        requestsSetups: res.data.data,
      };
    }
  } catch (err) {
    return getFailedApiCallResponse(err);
  }
  return { success: false };
};

export const getRequestSetupsByRole = async (
  own: boolean,
  id?: string,
): Promise<{
  success: boolean;
  requestsSetupsByRole?: Types.IRequestToSubmit[];
  error?: string;
}> => {
  if (!own && !id) {
    alert('impossible to get request setups by role');
    return { success: false };
  }

  const url = own ? `/gateway/requests/setups/own` : `/gateway/requests/setups/role/${id}`;

  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(url, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return {
        success: true,
        requestsSetupsByRole: res.data.data ? res.data.data : [],
      };
    }
  } catch (err) {
    return getFailedApiCallResponse(err);
  }
  return { success: false };
};

export const addRequestSetup = async (data: Types.IReqTypeData) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      '/gateway/requests/setups',
      { ...data },
      { headers: { 'X-ACCESS-TOKEN': accessToken } },
    );
    if (_.get(res, 'data.success')) {
      return { success: true, request: res.data.request };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteRequestSetup = async (id: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.delete(`/gateway/requests/setups/${id}`, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 204) {
      return { success: true, data: res.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateRequestSetup = async (id: string, data: Types.IReqTypeData) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.put(
      `/gateway/requests/setups/${id}`,
      { ...data },
      { headers: { 'X-ACCESS-TOKEN': accessToken } },
    );
    if (res.status === 200) {
      return { success: true, request: res.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: true };
};

export const submitRequest = async (
  formData: FormData,
  requestSetupId: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(`/gateway/requests/case/${requestSetupId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'X-ACCESS-TOKEN': accessToken,
      },
    });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateRequestPriority = async (
  setupId: string,
  caseId: string,
  payload: { Priority: string },
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.put(`/gateway/requests/case/${setupId}/${caseId}`, payload, {
      headers: {
        'X-ACCESS-TOKEN': accessToken,
      },
    });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const addRequestComment = async (
  setupId: string,
  caseId: string,
  text: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      `/gateway/requests/case/${setupId}/${caseId}/comment`,
      { text },
      {
        headers: {
          'X-ACCESS-TOKEN': accessToken,
        },
      },
    );
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateRequestCase = async (
  setupId: string,
  caseId: string,
  payload: { Status: RequestStatusesEnum.closed } | FormData,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.put(`/gateway/requests/case/${setupId}/${caseId}`, payload, {
      headers: {
        'X-ACCESS-TOKEN': accessToken,
      },
    });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getSubmittedRequestsList = async (): Promise<{
  success: boolean;
  submittedRequestsList?: Types.SalesforceRequestCase[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/requests/case/list?systemType=Salesforce`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return {
        success: true,
        submittedRequestsList: res.data.data,
      };
    }
  } catch (err) {
    return getFailedApiCallResponse(err);
  }
  return { success: false };
};

export const syncNewUsers = async (
  userContext: Types.IUserContext,
  status?: boolean,
): Promise<Types.ISyncNewUsersResponse> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = status
      ? await axios.get('/gateway/teams/sync-users/status', {
          headers: { 'X-ACCESS-TOKEN': accessToken },
        })
      : await axios.post(
          '/gateway/teams/sync-users',
          {},
          {
            headers: { 'X-ACCESS-TOKEN': accessToken },
          },
        );
    if (res.status === 200) {
      if (status) userContext.setSyncNewUsersLoading(Boolean(res.data.working));
      const result: Types.ISyncNewUsersResponse = {
        success: true,
      };
      if (status) {
        result.data = {
          working: res.data.working,
          lastSyncUsersAmount: res.data.lastSyncUsersAmount,
        };
      }
      return result;
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const syncUsersInfo = async (
  userContext: Types.IUserContext,
  status?: boolean,
): Promise<Types.ISyncUsersInfoResponse> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = status
      ? await axios.get('/gateway/teams/sync-users-info/status', {
          headers: { 'X-ACCESS-TOKEN': accessToken },
        })
      : await axios.post(
          '/gateway/teams/sync-users-info',
          {},
          {
            headers: { 'X-ACCESS-TOKEN': accessToken },
          },
        );
    if (res.status === 200) {
      if (status) {
        userContext.setSyncUsersInfoLoading(Boolean(res.data.working));
      }
      const result: Types.ISyncUsersInfoResponse = {
        success: true,
      };
      if (status) {
        result.data = {
          working: res.data.working,
        };
      }
      return result;
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteRole = async (roleId: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.delete(`/gateway/roles/${roleId}`, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getUser = async (): Promise<{
  success: boolean;
  user?: Types.User;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get('/gateway/users/user', { headers: { 'X-ACCESS-TOKEN': accessToken } });
      if (res.status === 200) {
        Honeybadger.setContext({
          user_id: res.data?.oktaId,
          user_email: res.data?.email_address,
        });
        return { success: true, user: res.data };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const updateUser = async (
  data: Partial<Types.User>,
  id?: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const url = id ? `gateway/users/user/${id}` : 'gateway/users/user';

    const res = await axios.put(url, data, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getAdvisees = async (): Promise<{
  success: boolean;
  data?: Types.Team[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('gateway/teams/advisory-team/as-advisor', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return { success: true, data: res.data };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getTeams = async (): Promise<{
  success: boolean;
  data?: Types.Officer[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get('gateway/teams/advisory-team', {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data.advisors };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getEmployees = async (): Promise<{
  success: boolean;
  data?: Types.Officer[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get('gateway/directory-groups', {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getLastLoginDate = async (
  oktaId: string,
): Promise<{
  success: boolean;
  data?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get(`gateway/users/last-login-date?userId=${oktaId}`, {
        headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID },
      });
      if (res.status === 200) {
        return { success: true, data: res.data.lastLoginDate };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteShadowedUser = async () => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.delete('/gateway/session/shadow-session', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getShadowedUserData = async (id: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      '/gateway/session/shadow-session',
      { id },
      { headers: { 'X-ACCESS-TOKEN': accessToken } },
    );
    console.log('shadowUserData res', res);

    return { success: true, data: res.data?.newSession };
  } catch (e) {
    throw getFailedApiCallResponse(e);
  }
};

export const getShadowedRoleData = async (id: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      '/gateway/session/shadow-role',
      { id },
      { headers: { 'X-ACCESS-TOKEN': accessToken } },
    );
    return { success: true, data: res.data?.newSession };
  } catch (e) {
    throw getFailedApiCallResponse(e);
  }
};

export const deleteShadowedRole = async () => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.delete('/gateway/session/shadow-role', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getQuickSightEmbedUrl = async (
  dashboardId: string,
): Promise<{
  success: boolean;
  EmbedUrl?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get(`gateway/quik-sight/dashboard-embed-url?dashboardId=${dashboardId}`, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, EmbedUrl: res.data.data.EmbedUrl || '' };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getSpaces = async (): Promise<{
  success: boolean;
  data?: Types.ISpaces[];
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    if (accessToken) {
      const res = await axios.get('gateway/spaces', {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data.data };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getSpace = async (
  slug: string,
  id: string,
): Promise<{
  success: boolean;
  data?: Types.ISpaces;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const query = slug ? `slug=${slug}` : `id=${id}`;
    if (accessToken) {
      const res = await axios.get(`gateway/spaces/space?${query}`, {
        headers: { 'X-ACCESS-TOKEN': accessToken },
      });
      if (res.status === 200) {
        return { success: true, data: res.data.data };
      }
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getSpaceVisibilityGroups = async (): Promise<{
  success: boolean;
  data?: Types.ISpaceVisibilityGroups[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/spaces/space-content-groups', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const editSpace = async (
  id: string,
  data: Partial<Types.INewSpaceData>,
  type: Types.SpaceItemType,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const payload = { ...data, frontend_url: `${window.location.origin}/spaces/data?${type}=` };
    const accessToken = Cookie.getAccessToken();
    const res = await axios.patch(`/gateway/spaces/${id}`, payload, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const addNewSpace = async (
  data: Partial<Types.INewSpaceData>,
  type: Types.SpaceItemType,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const payload = { ...data, frontend_url: `${window.location.origin}/spaces/data?${type}=` };
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post('/gateway/spaces', payload, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (_.get(res, 'data.success')) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getPortfolioData = async (): Promise<{
  success: boolean;
  data?: Types.IPortfolioAccounting[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/portfolio/addepar/portfolio', { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getTransactionsData = async (): Promise<{
  success: boolean;
  data?: Types.IRecentTransactionsData[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/portfolio/addepar/transactions', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const deleteSpace = async (
  id: string,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.delete(`/gateway/spaces/${id}`, { headers: { 'X-ACCESS-TOKEN': accessToken } });

    if (res.status === 204) {
      return { success: true };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const setGatewayColorAndTitle = async (data: {
  gateway_primary_color?: string;
  spaces_display_name?: string;
}): Promise<{
  success: boolean;
  type: string;
  error?: string;
}> => {
  let returnType = '';
  if (data.gateway_primary_color) returnType = 'color ';
  if (data.spaces_display_name) returnType += 'title';
  returnType = returnType.trim().split(' ').join(', ');

  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.put('/gateway/utils/global_config', data, { headers: { 'X-ACCESS-TOKEN': accessToken } });
    if (_.get(res, 'data.success')) {
      return {
        success: true,
        type: returnType,
      };
    }
  } catch (e) {
    return { success: false, error: String(e), type: returnType };
  }
  return { success: false, type: returnType };
};

export const setGatewayLogo = async (
  file: string | Blob,
): Promise<{
  success: boolean;
  type: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const formData = new FormData();
    formData.append('file', file);

    const res = await axios.post('/gateway/utils/media?original_name=logo&folder=utils', formData, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (_.get(res, 'data.success')) {
      return {
        success: true,
        type: 'logo',
      };
    }
  } catch (e) {
    return { success: false, error: String(e), type: 'logo' };
  }
  return { success: false, type: 'logo' };
};

export const getGatewayLogo = async (): Promise<{
  success: boolean;
  data?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get(`/gateway/utils/media/${GATEWAY_ID}?original_name=logo`, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data.media.url,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
export const setGatewayFavicon = async (
  file: string | Blob,
): Promise<{
  success: boolean;
  type: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const formData = new FormData();
    formData.append('file', file);

    const res = await axios.post('gateway/utils/media?original_name=favicon', formData, {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });
    if (_.get(res, 'data.success')) {
      return {
        success: true,
        type: 'favicon',
      };
    }
  } catch (e) {
    return {
      success: false,
      error: String(e),
      type: 'favicon',
    };
  }
  return { success: false, type: 'favicon' };
};

interface IGetOktaSessionStatusResponse {
  success: boolean;
  isActive?: boolean;
  error?: string;
  expiresAt?: string;
}

export const getOktaSessionStatus = async (baseUrl: string): Promise<IGetOktaSessionStatusResponse> => {
  const maxRetries = 3;
  let response: IGetOktaSessionStatusResponse = { success: false };

  for (let i = 0; i < maxRetries; i++) {
    try {
      const res = await axios.get(`${baseUrl}/api/v1/sessions/me`, {
        withCredentials: true,
      });

      if (res.status === 200) {
        response = {
          success: true,
          isActive: true,
          expiresAt: res.data.expiresAt,
        };
        break;
      }
    } catch (e) {
      if (i < maxRetries - 1) {
        await new Promise((resolve) => setTimeout(resolve, 2000)); // pause for 2 seconds before next session/me attempt
      } else {
        return getFailedApiCallResponse(e);
      }
    }
  }

  return response;
};

export const deleteOktaSession = async (baseUrl: string) => {
  try {
    const instance = axios.create({ withCredentials: true, baseURL: baseUrl });
    await instance.delete(`/api/v1/sessions/me`);
  } catch (e) {
    return getFailedApiCallResponse(e);
  }

  return { success: false };
};

export const getGlobalCosmicConfigPublic = async (): Promise<{
  success: boolean;
  data?: IGlobalCosmicConfig;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/utils/global_config/public', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getGlobalCosmicConfig = async (): Promise<{
  success: boolean;
  data?: {
    metadata: {
      support_request: string;
    };
  };
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/utils/global_config', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getEmailActions = async (): Promise<{
  success: boolean;
  data?: { [key: string]: string };
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/email/actions', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return {
        success: true,
        data: res.data.data,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getBaseUrlData = async (): Promise<{
  success: boolean;
  url?: string;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/utils/okta-instance', {
      headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID },
    });

    if (res.status === 200) {
      return {
        success: true,
        url: String(res.data.data.baseUrl),
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getOktaTokenInfo = async (
  token: string,
  token_type_hint: Types.OktaTokenTypeHint,
): Promise<{ success: boolean; active?: boolean; expiresAt?: string }> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.post(
      '/gateway/utils/okta-introspect',
      { token, token_type_hint },
      { headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID } },
    );

    if (_.get(res, 'data.success')) {
      return {
        success: true,
        active: res.data.data.active,
        expiresAt: res.data.data.exp,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const refreshOktaSession = async (
  baseUrl: string,
): Promise<{
  success: boolean;
  isActive?: boolean;
  error?: string;
  expiresAt?: string;
}> => {
  try {
    const instance = axios.create({
      withCredentials: true,
      baseURL: baseUrl,
    });
    const res = await instance.post(`/api/v1/sessions/me/lifecycle/refresh`);

    if (res.status === 200) {
      return {
        success: true,
        isActive: true,
        expiresAt: res.data.expiresAt,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getChartAttributes = async (): Promise<{
  success: boolean;
  attributes?: Types.IChartAttributes[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/portfolio/addepar/chart/attributes', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return {
        success: true,
        attributes: res.data.data || [],
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getChartArguments = async (): Promise<{
  success: boolean;
  arguments?: Types.IChartArguments[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/portfolio/addepar/chart/arguments ', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return {
        success: true,
        arguments: res.data.data || [],
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const sendSupportEmail = async (
  action: string,
  payload: any,
): Promise<{
  success: boolean;
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();

    const res = await axios.post(`/gateway/email?action=${action}`, payload, {
      headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID },
    });

    if (_.get(res, 'data.success') === true) {
      return {
        success: true,
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};

export const getChartGroupsData = async (): Promise<{
  success: boolean;
  groups?: string[];
  error?: string;
}> => {
  try {
    const accessToken = Cookie.getAccessToken();
    const res = await axios.get('/gateway/portfolio/addepar/chart/groups', {
      headers: { 'X-ACCESS-TOKEN': accessToken },
    });

    if (res.status === 200) {
      return {
        success: true,
        groups: res.data.data || [],
      };
    }
  } catch (e) {
    return getFailedApiCallResponse(e);
  }
  return { success: false };
};
