import { styled } from '@mui/material/styles';

const SimpleLayout = styled('div')<{ dir: 'ltr' | 'rtl' }>`
  .ReactModalPortal {
    font-weight: bold;
    button,
    input {
      border-radius: 3.74px;
      height: 60px;
      display: block;
      color: #fff;
      font-size: 16px;
      letter-spacing: 1px;
      background-color: rgba(255, 255, 255, 0.3);
      border: #fff;
      width: 320px;
      margin: 10px auto 10px auto;
      font-family:
        Proxima Nova,
        sans-serif;
    }
    button {
      cursor: pointer;
      font-weight: bold;
    }
    input {
      background-color: #fff;
      color: #919bd1;
      border: 1px solid #919bd1;
      padding-left: 10px;
      ::placeholder {
        color: #2c1b68;
        opacity: 0.5;
      }
      :focus {
        outline: none;
      }
    }
    .modal-title {
      font-size: 20px;
      max-width: 300px;
      line-height: 24px;
      padding: 0px 10px;
      text-align: center;
      margin: 15px auto 20px auto;
      color: #0a354c;
      font-family: Neuzeit-Grotesk, sans-serif;
    }
    .modal-title1 {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #0a354c;
      font-family: Neuzeit-Grotesk, sans-serif;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .modal-title2 {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #0a354c;
      font-family: Neuzeit-Grotesk, sans-serif;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
    }
    .modal-title3 {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #0a354c;
      font-family: Neuzeit-Grotesk, sans-serif;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      max-width: 350px;
      margin-top: 10px;
    }
    .close {
      right: 15px;
      top: 10px;
      position: absolute;
      font-weight: normal;
      cursor: pointer;
      color: black;
    }
  }
  .outer-container {
    .main-container {
      color: #fafafa;
      .menu-item {
        border: none;
        .menu-title {
          color: rgba(255, 255, 255, 0.5);
          &:hover {
            color: rgba(255, 255, 255);
          }
        }
      }
    }
  }
  .auth-outer-container {
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  .outer-container.dashboard {
    background-color: #fff;
  }
  .user-container {
    cursor: pointer;
  }
  nav {
    .user-container {
      .user-picture {
        border: none;
        &.image {
          background-size: 15px;
          background-position: center;
        }
      }
      .user-name,
      .user-title {
        font-size: 0.9em;
      }
      .user-name {
        font-weight: bold;
      }
      .user-title {
        text-decoration: underline;
      }
    }
  }

  html {
    font-size: 16px;
  }
  .column.small {
    flex: 0.15 !important;
  }
  .auth-layout {
    text-align: center;
    .main-content {
      margin: auto;
      padding: 2.5rem;
      display: flex;
      flex: 1 1 0%;
      justify-content: center;
      @media screen and (max-width: 600px) {
        padding: 0;
      }
    }
  }

  aside.settings-sidebar {
    background-color: #0b354c;
    transition: transform 0.3s ease;
    width: 19rem;
    overflow: hidden;
    transform: translateX(100%);

    &.start {
      transform: translateX(100%);
    }
    &.expanded,
    &.expanded.start {
      transform: translateX(0);
    }
    .scrollable {
      width: 19rem;
      padding: 3.4rem 0.25rem;
    }
    .main-container {
      width: 19rem;
      transition: width 0.3s ease;
      overflow: hidden;
      background-color: #000 !important;
      .scrollable {
        width: 19rem;
      }
    }
  }
  .main-content {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media screen and (max-width: 0px) {
    .main-content {
      padding: 0.75rem !important;
    }
  }
  .with-margin {
    margin: 0 0.75rem 2rem 0;
  }
  .inline-block {
    display: inline-block;
  }
  .popover-card {
    margin-bottom: 0;
    width: 300px;
    box-shadow: none;
  }
  .btn {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    font-weight: 500;
    border: 2px solid transparent;
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }
  .ck-content {
    min-height: 20rem;
  }
  .Toastify__toast-container {
    width: auto;
    max-width: 700px;
    z-index: 9999999;
  }
  .Toastify__toast-container--top-center:has(div.tanBanner) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 100vw;
    margin-top: -10px;
    padding: 10px 0;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    .Toastify__toast {
      margin: 0;
    }
  }
`;

export default SimpleLayout;
