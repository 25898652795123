import axios from 'axios';
import _ from 'lodash';

import { Cookie } from '..';
import Config from '../../config';

const { GATEWAY_ID } = Config;

export const changePassword = async (oldPassword: string, newPassword: string, userId: string) => {
  try {
    const accessToken = Cookie.getAccessToken();
    const data = { oldPassword, newPassword, userId };
    const res = await axios.post('/auth/change-password', data, {
      headers: { 'X-ACCESS-TOKEN': accessToken, 'gateway-id': GATEWAY_ID },
    });
    if (res.data.success) {
      return { success: true };
    }
  } catch (e: any) {
    return { success: false, message: e?.response?.data?.message };
  }
  return { success: false };
};
